<template>
  <div
    class="p-tooltip"
    :direction="direction"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
    @mousemove="updateTooltipPosition"
  >
    <div class="p-tooltip__initiator">
      <slot />
    </div>

    <Teleport to="body">
      <div v-if="showTooltip" class="p-tooltip__item" :style="tooltipStyle">
        <div class="p-tooltip__content" :direction="direction">
          <slot name="content" />
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

const props = defineProps({
  direction: {
    type: String,
    default: 'bottom',
    validator: (val: string) =>
      [
        'left',
        'right',
        'top',
        'top-left',
        'top-right',
        'left-top',
        'right-top',
        'bottom',
        'bottom-left',
        'bottom-right',
      ].includes(val),
  },
})

const showTooltip = ref<boolean>(false)
const tooltipPosition = ref({ top: 0, left: 0, width: 0, height: 0 })

const updateTooltipPosition = (event: MouseEvent) => {
  const targetRect = (event.currentTarget as HTMLElement).getBoundingClientRect()
  tooltipPosition.value = {
    top: targetRect.top,
    left: targetRect.left,
    width: targetRect.width,
    height: targetRect.height,
  }
}

const positionMap: Record<string, () => Record<string, string>> = {
  left: () => ({
    top: `${tooltipPosition.value.top + tooltipPosition.value.height / 2}px`,
    left: `${tooltipPosition.value.left - 10}px`,
    transform: 'translateX(-100%) translateY(-50%)',
  }),
  right: () => ({
    top: `${tooltipPosition.value.top + tooltipPosition.value.height / 2}px`,
    left: `${tooltipPosition.value.left + tooltipPosition.value.width + 10}px`,
    transform: 'translateY(-50%)',
  }),
  top: () => ({
    top: `${tooltipPosition.value.top - 10}px`,
    left: `${tooltipPosition.value.left + tooltipPosition.value.width / 2}px`,
    transform: 'translateX(-50%) translateY(-100%)',
  }),
  bottom: () => ({
    top: `${tooltipPosition.value.top + tooltipPosition.value.height + 10}px`,
    left: `${tooltipPosition.value.left + tooltipPosition.value.width / 2}px`,
    transform: 'translateX(-50%)',
  }),
  'top-left': () => ({
    top: `${tooltipPosition.value.top - 10}px`,
    left: `${tooltipPosition.value.left - 10}px`,
    transform: 'translateX(0) translateY(-100%)',
  }),

  'top-right': () => ({
    top: `${tooltipPosition.value.top - 10}px`,
    left: `${tooltipPosition.value.left + tooltipPosition.value.width + 10}px`,
    transform: 'translateX(-100%) translateY(-100%)',
  }),

  'bottom-left': () => ({
    top: `${tooltipPosition.value.top + tooltipPosition.value.height + 10}px`,
    left: `${tooltipPosition.value.left - 10}px`,
    transform: 'translateX(0)',
  }),

  'bottom-right': () => ({
    top: `${tooltipPosition.value.top + tooltipPosition.value.height + 10}px`,
    left: `${tooltipPosition.value.left + tooltipPosition.value.width + 10}px`,
    transform: 'translateX(-100%)',
  }),
  'left-top': () => ({
    top: `${tooltipPosition.value.top}px`,
    left: `${tooltipPosition.value.left - 10}px`,
    transform: 'translateX(-100%)',
  }),
  'right-top': () => ({
    top: `${tooltipPosition.value.top}px`,
    left: `${tooltipPosition.value.left + tooltipPosition.value.width + 10}px`,
    transform: 'translateY(0)',
  }),
}

const tooltipStyle = computed(() => {
  const calculatePosition = positionMap[props.direction] || positionMap.bottom
  return calculatePosition()
})
</script>
